import {axiosData} from "@/store/api/axios";

const state = () => ({
  eventos: JSON.parse(localStorage.getItem('eventos')) || null,
  evento: JSON.parse(localStorage.getItem('evento')) || null,

});

const getters = {
  getEventos(state) {
    return state.eventos;
  },
  getEvento(state) {
    return state.evento;
  },
};

const actions = {
  setEvento({ commit }, payload) {
    commit('SET_EVENTO', payload);
    payload
        ? localStorage.setItem('evento', JSON.stringify(payload))
        : localStorage.removeItem('evento');
  },
  setEventos({ commit }, payload) {
    commit('SET_EVENTOS', payload);
    payload
        ? localStorage.setItem('eventos', JSON.stringify(payload))
        : localStorage.removeItem('eventos');
  },
  async nuevoEvento(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'eventos/nuevo',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        this.dispatch('eventos/setEvento',data);

        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async grabarEvento(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'eventos/grabaevento',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async grabarContactoEvento(_,payload){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'eventos/contactos/grabar',
          payload,
          'post',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async borrarEvento(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'eventos/'+tag,
          null,
          'delete',
          false,
          false
      );


      this.dispatch('ui/handleLoaderBtn', false);
      return data;

    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async getEventosVerifica(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'eventos/verifica/'+tag,
          'get',
          false,
          false
      );


        this.dispatch('ui/handleLoaderBtn', false);
        return data;

    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
  async getEventosByCliente(_,tag){
    this.dispatch('ui/handleLoaderBtn', true);
    try {
      const data = await axiosData(
          true,
          'eventos/cliente/'+tag,
          'get',
          false,
          false
      );

      if(data){
        this.dispatch('ui/handleLoaderBtn', false);
        this.dispatch('eventos/setEventos',data);
        return data;
      }
    } catch (error) {
      this.dispatch('ui/handleLoaderBtn', false);
      throw new Error(`Tunay api failed, error: ${error}`);
    }
  },
};

const mutations = {
  SET_EVENTOS(state, payload) {
    state.eventos = payload;
    localStorage.setItem('eventos', JSON.stringify(payload));
  },
  SET_EVENTO(state, payload) {
    state.evento = payload;
    localStorage.setItem('evento', JSON.stringify(payload));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
